export default {
  monthNames: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  monthNamesCir: {
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    may: 'May',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Dec',
  },
  week: {
    mon: 'mon',
    tue: 'Tue',
    wed: 'wed',
    thu: 'thu',
    fri: 'Fri',
    sat: 'sat',
    sun: 'sun',
  },
  verification: {
    INITIAL: {
      action: '',
      name: 'Verify your profile',
      description:
        'Fill in the required data in the verification window to gain access to the platform.',
    },
    REJECTED: {
      action: 'Repeat',
      name: 'You are not verified',
      description: 'You can try again.',
    },
    APPROVED: {
      action: 'Ok',
      name: 'Verification passed',
      description: 'The functionality of the platform is available to you.',
    },
    WAITING: {
      action: '',
      name: '',
      description: '',
    },
    INVALID_CODE: 'Invalid code!',
    REQUIRE_FIELDS: 'Fill in the required fields.',
    REQUIRE_CODE: 'Enter code.',
    INVALID_PHONE: 'Check the entered phone number.',
    PHONE_VERIFIED: 'Phone number has been verified',
    modal: {
      title: 'Verify your phone number',
      send: 'Send',
      timerText: 'Call again in',
      newCall: 'Request a new reset call',
      onNumber: 'To number',
      enterLast4Digits: 'You should receive a reset call, please enter the last 4 digits of the number.',
    },
  },
  imageOnly: 'Images only!',
  size: {
    B: 'B',
    KB: 'KB',
    MB: 'MB',
  },
  structure: {
    auth: {
      password: 'Password',
      toComeIn: 'Login',
      register: 'Register',
      toSendALetter: 'Send a letter',
      toSendCode: 'Send sms code',
      newPassword: 'New password',
      repeat: 'Repeat',
      updatePassword: 'Update password',
      enterCodeSms: 'Enter the code from sms',
    },
    plantation: {
      name: 'Plantation name',
      country: 'Select a country',
      enterState: 'Enter state',
      enterCity: 'Enter a city',
      enterAddress: 'Enter the address',
      enterPostalCode: 'Enter postal code',
      selectCurrency: 'Select currency',
      enterBeneficiary: 'Enter the beneficiary',
      enterSWIFTorBIC: 'Enter SWIFT or BIC',
    },
    profile: {
      name: 'Name',
      enterName: 'Enter a name',
      surname: 'Last name',
      enterLastName: 'Enter last name',
      telephone: 'Telephone',
      enterPhone: 'Enter your phone number',
      companyName: 'Company name',
      enterCompanyName: 'Enter your company name',
      country: 'Country',
      chooseCountry: 'Choose a country',
      city: 'City',
      enterCity: 'Enter a city',
      companyAddress: 'Company address',
      enterCompanyAddress: 'Enter the company address',
      index: 'Index',
      enterIndex: 'Enter index',
      TIN: 'TIN',
      enterTIN: 'Enter your TIN',
      siteCompany: 'Company website',
      linkToWebsite: 'Insert a link to the company website',
      sendForVerification: 'Send for verification',
    },
  },
  table: {
    noOrders: "You don't have any orders yet",
    issueDate: 'Issue date',
    deliveryFrom: 'Delivery from:',
    sum: 'Sum',
    status: 'Status',
    isPaid: 'Payment Status',
    paid: 'Paid',
    comment: 'Сomment',
    wait_paid: 'Awaiting payment',

  },
  typeFilter: {
    alstromeria: 'Alstromeria',
    gypsophila: 'Gypsophila',
    dyes: 'Dyes',
    sprayRoses: 'Spray Roses',
    roses: 'Roses',
    chrysanthemum: 'Chrysanthemum',
    gvozdika: 'Carnations/China',
    evkalipt: 'Eucalyptus/China',
    giperikum: 'Hypericum/China',
    pistash: 'Pistach',
  },
};
