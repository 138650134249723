<script setup lang="ts">
import { vMaska } from 'maska';
import { usePhoneMasks } from './masks';
import { StructureProps } from '@/interfaces';

const { masks } = usePhoneMasks()

const { data, input } = defineProps<{
  input: StructureProps['input'];
  data: StructureProps['data'];
}>();

const selectedCode = ref('RUS');
const showList = ref(false);

const currentCode = computed(() => {
  return masks[selectedCode.value as 'RUS'];
});

const currentCodeImage = computed(() => {
  return `/images/countries/${selectedCode.value}.svg`;
});

const currentCodePlaceholder = computed(() => {
  return currentCode.value.mask.replaceAll('#', '9');
});

const currentCodeMask = computed(() => {
  return `${currentCode.value.code}${currentCode.value.mask}`;
});

const selectCode = function (code: string, reset = false) {
  selectedCode.value = code;
  useModalStore().phoneCode = code;
  showList.value = false;

  if (reset) data[input.id] = currentCode.value.code;
};

const detectPhone = function () {
  if (!data[input.id]) return;
  const phone_code = data[input.id].split('(')[0];
  if (!phone_code) return;

  const current_code_index = Object.values(masks).findIndex((mask) => mask.code === phone_code);
  if (current_code_index === -1) return;

  const current_code_name = Object.keys(masks)[current_code_index];
  selectCode(current_code_name);
};
detectPhone();
</script>

<template>
  <section class="modal-phone">
    <lazy-common-input-title
      v-if="input.name"
      :name="input.name"
      :required="input.required"
    ></lazy-common-input-title>
    <common-drop :container="{ top: '46px', left: '0px' }" :is-disable="input.disabled">
      <template #header>
        <header class="modal-phone--header flex flex-ic" :class="{ disabled: input.disabled }">
          <div class="code flex-c cursor-pointer" @click="showList = !showList">
            <div class="code--img">
              <common-image :image="currentCodeImage"></common-image>
            </div>
            <div class="code--value">{{ currentCode.code }}</div>
          </div>
          <input
            type="text"
            v-model="data[input.id]"
            v-maska
            :disabled="input.disabled"
            :data-maska="currentCodeMask"
            :placeholder="currentCodePlaceholder"
          />
        </header>
      </template>
      <template #container="{ toggle }">
        <main class="modal-phone--container flex-col">
          <div
            class="element cursor-pointer flex flex-ic"
            v-for="(code, name) in masks"
            :key="name"
            @click="
              () => {
                selectCode(name, true);
                toggle();
              }
            "
          >
            <div class="element--img">
              <common-image :image="`/images/countries/${name}.svg`"></common-image>
            </div>
            <div class="element--name">{{ code.name }}</div>
          </div>
        </main>
      </template>
    </common-drop>
  </section>
</template>

<style scoped lang="scss">
.modal-phone {
  @apply rounded-xl;
  &--header {
    @apply relative rounded-md py-2 px-4 gap-0.5 border border-solid border-gray-4;
    .code {
      @apply relative;
      gap: 6px;
      &--img {
        @apply w-4 h-4 rounded-sm;
        img {
          width: inherit;
          height: inherit;
        }
      }
    }
    input {
      @apply absolute left-[38px];
    }
    &.disabled {
      @apply bg-gray-4 text-gray-2;
      input {
        @apply bg-gray-4 text-gray-2;
      }
    }
  }
  &--container {
    max-height: 150px;
    @apply gap-2 rounded-lg overflow-auto py-2 px-3 border border-solid border-gray-4 text-sm;
    .element {
      @apply gap-3 pb-2;
      &--img {
        @apply w-5 h-5 rounded-md;
        img {
          width: inherit;
          height: inherit;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid;
        @apply border-gray-4;
      }
    }
  }
}
</style>
