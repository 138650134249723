import { AccountTypeName, StructureInput, StructureModule, AccountTypeList } from '@/interfaces';
import { objectCollection } from '..';

export const useInitialInputsBalance = (data: Record<string, any>) => {
  const { t } = useI18n();

  const inputsQrcode = (): Array<StructureInput> => [
    {
      id: 'qrcode',
      name: '',
      placeholder: '',
      grid: '1 / 13',
      type: 'qrcode',
      show: true,
      required: true,
    },
  ];

  const getInputsCash = (): Array<StructureInput> => [
    // {
    //   id: 'type',
    //   name: t('components.common.inputs.selectAccount'),
    //   placeholder: '',
    //   grid: '1 / 13',
    //   type: 'selector-single',
    //   drop: AccountTypeList,
    //   drop_enum: AccountTypeName,
    //   show: true,
    //   required: true,
    // },
    {
      id: 'amount',
      name: t('components.common.inputs.amount'),
      placeholder: t('components.common.inputs.enterAmount'),
      grid: '1 / 13',
      type: 'text',
      show: true,
      required: true,
    },
  ];

  const getBalance = (data: Record<string, any>): Array<StructureModule> => {
    return [
      {
        id: 'balance-qrcode',
        data: objectCollection(data, ['method', 'qrcode', 'amount']),
        inputs: inputsQrcode(),
        classList: 'text-sm',
      },
      {
        id: 'balance-cash',
        data: objectCollection(data, ['method']),
        inputs: getInputsCash(),
        classList: 'text-sm',
      },
      {
        id: 'balance-card',
        data: objectCollection(data, ['method']),
        inputs: getInputsCash(),
        classList: 'text-sm',
      },
      {
        id: 'balance-crypto',
        data: objectCollection(data, ['method', 'amount']),
        inputs: getInputsCash(),
        classList: 'text-sm',
      },
    ];
  };

  return {
    inputsQrcode,
    inputsCash: getInputsCash(),
    balance: getBalance(data),
  }
}
