export default {
  assortment: () => import("/home/ifc_web/layouts/assortment.vue").then(m => m.default || m),
  auth: () => import("/home/ifc_web/layouts/auth.vue").then(m => m.default || m),
  base: () => import("/home/ifc_web/layouts/base.vue").then(m => m.default || m),
  cart: () => import("/home/ifc_web/layouts/cart.vue").then(m => m.default || m),
  catalog: () => import("/home/ifc_web/layouts/catalog.vue").then(m => m.default || m),
  default: () => import("/home/ifc_web/layouts/default.vue").then(m => m.default || m),
  offer: () => import("/home/ifc_web/layouts/offer.vue").then(m => m.default || m),
  operation: () => import("/home/ifc_web/layouts/operation.vue").then(m => m.default || m),
  profile: () => import("/home/ifc_web/layouts/profile.vue").then(m => m.default || m),
  settings: () => import("/home/ifc_web/layouts/settings.vue").then(m => m.default || m),
  test: () => import("/home/ifc_web/layouts/test.vue").then(m => m.default || m),
  transaction: () => import("/home/ifc_web/layouts/transaction.vue").then(m => m.default || m)
}