<script setup lang="ts">
</script>

<template>
  <section class="auth">
    <auth-background v-if="$device.isDesktop" class="auth-background"></auth-background>
    <section class="auth-inner">
      <!-- <auth-routing/> -->

      <div class="auth-container">
        <auth-header/>
        <slot></slot>
      </div>
    </section>
  </section>
</template>

<style lang="scss" scoped>
.auth {
  @apply w-full h-full flex flex-col lg:flex-row;

  > section {
    @apply lg:w-1/2 h-full;
  }

  &-background {
    @apply hidden md:flex;
  }

  &-inner {
    @apply flex flex-col items-center justify-center
    relative pb-10 pt-20 px-10 md:p-[60px] flex-auto;
  }

  &-container {
    @apply flex flex-col gap-8 items-center justify-center
      w-[400px] md:w-full lg:w-[85%]
      max-w-full md:max-w-[500px]
      pt-5 lg:pt-0;
  }
}
</style>
