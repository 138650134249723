export default {
  catalog: 'Catalog',
  assortment: 'Assortment',
  transactions: 'Transactions',
  operationsHistory: 'Operations history',
  operationsProducts: 'Operations',
  transfers: 'Transfers',
  exchange: 'Exchange',
  auction: 'Auction',
  information: 'Information',
  verification: 'Verification',
  replenishments: 'Replenishments',
  topUpBalance: 'Top up balance',
  betweenYourAccounts: ' Between your accounts',
  nameless: 'Nameless',
  profile: 'Profile',
  historyOfOrders: 'History of orders',
  logout: 'Logout',
  payments: 'Payments',
  acceptance: 'Acceptance',
  writeoff: 'Write-off',
  margin: 'Margin',
  delivery: 'Delivery',
  settings: 'Settings',
}
