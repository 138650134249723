<script setup lang="ts">
import { NavigationItem, UserVerificationStatus, UserRole } from '@/interfaces';
// import { navigationList } from '@/utils/data/navigation';
const { t } = useI18n();

const userStore = useUserStore(); 
const isApproved = computed(
  () => userStore.getVerificationStatus === UserVerificationStatus.APPROVED,
);

const navigationList = computed<NavigationItem[]>(() => userStore.getRole === UserRole.ADMIN
  ? [
    { path: Page.CATALOG, name: t('components.common.header.catalog') },
    // { path: Page.TRANSACTION, name: t('components.common.header.transactions') },
    { path: Page.HISTORY, name: t('components.common.header.historyOfOrders') },
    { path: Page.OPERATION, name: t('components.common.header.operationsProducts') },
    { path: Page.ASSORTMENT, name: t('components.common.header.assortment') },
    { path: Page.SETTINGS, name: t('components.common.header.settings') },
  ]
  : [
    { path: Page.CATALOG, name: t('components.common.header.catalog') },
    { path: Page.HISTORY, name: t('components.common.header.historyOfOrders') },
  ]
);

console.log('navigationList', navigationList);

const checkAdmin = (item: NavigationItem) => {
  if ((item.path === Page.ASSORTMENT || item.path === Page.OPERATION || item.path === Page.SETTINGS) && userStore.getRole !== UserRole.ADMIN) {
    return false
  }
  return true
}

</script>

<template>
  <nav>
    <ul class="flex flex-ic">
      <common-page-header-navigation-item v-for="(item, index) of navigationList" :key="index" :item="item"
        :is-approved="isApproved && checkAdmin(item)"></common-page-header-navigation-item>
    </ul>
  </nav>
</template>

<style scoped lang="scss">
nav {
  flex: 1 1 auto;

  ul {
    @media screen and (max-width: $md) {
      flex-direction: column;
      align-items: end;
      color: black;
      font-size: 24px;
    }

    @apply gap-6;
  }
}
</style>
