<script setup lang="ts">
</script>

<template>
  <catalog-image></catalog-image>
  <section class="operation">
    <common-page-header></common-page-header>
    <section class="operation-container flex-col">
      <div class="operation-header flex-col">
        <div class="flex-c-b">
          <h1>Операции</h1>
        </div>
      </div>
      <slot></slot>
    </section>
    <!-- <catalog-footer></catalog-footer> -->
  </section>
</template>

<style lang="scss" scoped>
.operation {
  @apply padding-top-for-fixed-header;
  
  &-container {
    @apply px-4 md:px-[5%] py-8 gap-8;
    height: 100%;
  }
}
</style>
