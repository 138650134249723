import {
  TableOptions,
  OrderStatusList,
  TransactionStatusList,
  PurchaseStatusList,
} from '@/interfaces';

const SelectedOrderStatusList = OrderStatusList.map((el) => ({ ...el, selected: true }));
const SelectedPurchaseStatusList = PurchaseStatusList.map((el) => ({ ...el, selected: true }));
const SelectedTransactionStatusList = TransactionStatusList.map((el) => ({
  ...el,
  selected: true,
}));

export const operationTableAcceptance: TableOptions = {   
  id: 'operationAcceptance',
  name: '',
  width: '100%',
  min_width: '1000px',
  request: '/operation/list/acceptance',
  empty: 'У вас пока еще нет ни одной операции',
  elements: [
    { id: 'id', name: '№ Операции', width: '25%' },
    { id: 'createdAt', name: 'Дата и время', width: '25%' },
    { id: 'type', name: 'Тип Операции', width: '25%' },
    { id: 'comment', name: 'Комментарий', width: '25%' },
  ],
};

export const operationTableWriteoff: TableOptions = {
  id: 'operationWriteoff',
  name: '',
  width: '100%',
  min_width: '1000px',
  request: '/operation/list/writeoff',
  empty: 'У вас пока еще нет ни одной операции',
  elements: [
    { id: 'id', name: '№ Операции', width: '25%' },
    { id: 'createdAt', name: 'Дата и время', width: '25%' },
    { id: 'type', name: 'Тип Операции', width: '25%' },
    { id: 'comment', name: 'Комментарий', width: '25%' },
  ],
};

export const transactionTable: TableOptions = {
  id: 'transaction',
  name: '',
  width: '100%',
  min_width: '1000px',
  request: '/transaction/list',
  empty: 'У вас пока еще нет ни одной операции',
  elements: [
    { id: 'number', name: '№ Заявки', width: '10%' },
    { id: 'amount', name: 'Сумма', width: '15%' },
    { id: 'createdAt', name: 'Дата и время', width: '15%' },
    { id: 'method', name: 'Метод оплаты', width: '15%' },
    {
      id: 'status',
      name: 'Статус',
      width: '15%',
      filter: { id: 'status', type: 'checkbox', list: SelectedTransactionStatusList },
    },
    { id: 'comment', name: 'Комментарий', width: '30%' },
  ],
};

export const transactionTransferTable: TableOptions = {
  id: 'order',
  name: '',
  width: '100%',
  min_width: '1000px',
  request: '/order/list',
  empty: 'У вас пока еще нет ни одной операции',
  actions: {
    table: true,
  },
  elements: [
    { id: 'number', name: '№ Заявки', width: '10%' },
    { id: 'amountAccount', name: 'Сумма списания', width: '15%' },
    { id: 'amountRecipient', name: 'Сумма пополнения', width: '15%' },
    { id: 'course', name: 'Курс', width: '10%' },
    { id: 'createdAt', name: 'Дата и время', width: '10%' },
    { id: 'plantation', name: 'Получатель', width: '20%' },
    {
      id: 'status',
      name: 'Статус',
      width: '15%',
      filter: { id: 'status', type: 'checkbox', list: SelectedOrderStatusList },
    },
    { id: '', name: '', width: '5%' },
  ],
};

export const usePurchaseTable = () => {
  const { t } = useI18n();

  const getPurchaseTable = (): TableOptions => ({
    id: 'purchase',
    name: '',
    width: '100%',
    min_width: '1000px',
    request: '/purchase/list',
    empty: t('dictionaries.table.noOrders'),
    actions: {
      table: true,
      click: false,
    },
    elements: [
      { id: 'orderIntId', name: '№', width: '15%' },
      { id: 'createdAt', name: t('dictionaries.table.issueDate'), width: '15%' },
      { id: 'deliveryFrom', name: t('dictionaries.table.deliveryFrom'), width: '15%' },
      { id: 'totalPrice', name: t('dictionaries.table.sum'), width: '15%' },
      {
        id: 'status',
        name: t('dictionaries.table.status'),
        width: '20%',
        filter: { id: 'status', type: 'checkbox', list: SelectedPurchaseStatusList },
      },
      { id: 'isPaid', name: t('dictionaries.table.isPaid'), width: '15%' },

      { id: '', name: '', width: '5%' },
    ],
  });

  return {
    purchaseTable: getPurchaseTable(),
  }
}
