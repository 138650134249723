export default {
  monthNames: {
    january: 'Январь',
    february: 'Февраль',
    march: 'Март',
    april: 'Апрель',
    may: 'Май',
    june: 'Июнь',
    july: 'Июль',
    august: 'Август',
    september: 'Сентябрь',
    october: 'Октябрь',
    november: 'Ноябрь',
    december: 'Декабрь',
  },
  monthNamesCir: {
    jan: 'Янв',
    feb: 'Фев',
    mar: 'Мар',
    apr: 'Апр',
    may: 'Май',
    jun: 'Июн',
    jul: 'Июл',
    aug: 'Авг',
    sep: 'Сен',
    oct: 'Окт',
    nov: 'Ноя',
    dec: 'Дек',
  },
  week: {
    mon: 'пн',
    tue: 'вт',
    wed: 'ср',
    thu: 'чт',
    fri: 'пт',
    sat: 'сб',
    sun: 'вс',
  },
  verification: {
    INITIAL: {
      action: '',
      name: 'Выполните верификацию профиля',
      description:
        'Заполните необходимые данные в окне верификации, чтобы получить доступ к платформе.',
    },
    REJECTED: {
      action: 'Повторить',
      name: 'Вы не прошли верификацию',
      description: 'Можете повторить попытку.',
    },
    APPROVED: {
      action: 'Ок',
      name: 'Верификация пройдена',
      description: 'Вам доступен функционал платформы.',
    },
    WAITING: {
      action: '',
      name: '',
      description: '',
    },
    INVALID_CODE: 'Неверный код!',
    REQUIRE_FIELDS: 'Заполните обязательные поля.',
    REQUIRE_CODE: 'Введите код.',
    INVALID_PHONE: 'Проверьте номер телефона.',
    PHONE_VERIFIED: 'Номер телефона подтверждён',
    modal: {
      title: 'Подтвердите номер телефона',
      send: 'Отправить',
      timerText: 'Позвонить еще раз через',
      newCall: 'Запросить новый звонок-сброс',
      onNumber: 'На номер',
      enterLast4Digits: 'Вам должен поступить звонок-сброс, введите последние 4 цифры номера.',
    }
  },
  imageOnly: 'Только картинки!',
  size: {
    B: 'Б',
    KB: 'КБ',
    MB: 'МБ',
  },
  structure: { 
    auth: {
      password: 'Пароль',
      toComeIn: 'Войти',
      register: 'Зарегистрироваться',
      toSendALetter: 'Отправить письмо',
      toSendCode: 'Отправить sms код',
      newPassword: 'Новый пароль',
      repeat: 'Повторите',
      updatePassword: 'Обновить пароль',
      enterCodeSms: 'Введите код из sms',
    },
    plantation: {
      name: 'Название плантации',
      country: 'Выберите страну',
      enterState: 'Введите штат',
      enterCity: 'Введите город',
      enterAddress: 'Введите адрес',
      enterPostalCode: 'Введите почтовый индекс',
      selectCurrency: 'Выберите валюту',
      enterBeneficiary: 'Введите бенефициара',
      enterSWIFTorBIC: 'Введите SWIFT или BIC',
    },
    profile: {
      name: 'Имя',
      enterName: 'Введите имя',
      surname: 'Фамилия',
      enterLastName: 'Введите фамилию',
      telephone: 'Телефон',
      enterPhone: 'Введите номер телефона',
      companyName: 'Название компании',
      enterCompanyName: 'Введите название компании',
      country: 'Страна',
      chooseCountry: 'Выберите страну',
      city: 'Город',
      enterCity: 'Введите город',
      companyAddress: 'Адрес компании',
      enterCompanyAddress: 'Введите адрес компании',
      index: 'Индекс',
      enterIndex: 'Введите индекс',
      TIN: 'ИНН',
      enterTIN: 'Введите ИНН',
      siteCompany: 'Сайт компании',
      linkToWebsite: 'Вставьте ссылку на сайт компании',
      sendForVerification: 'Отправить на верификацию',
    },
  },
  table: {
    noOrders: 'У вас пока еще нет ни одного заказа',
    issueDate: 'Дата оформления',
    deliveryFrom: 'Доставка с:',
    sum: 'Сумма',
    status: 'Статус',
    isPaid: 'Статус Оплаты',
    comment: 'Комментарий',
    paid: 'Оплачен',
    wait_paid: 'Ожидает оплату',

  },
  typeFilter: {
    alstromeria: 'Альстромерия/Эквадор',
    gypsophila: 'Гипсофила',
    dyes: 'Красители',
    sprayRoses: 'Кустовые розы/Эквадор',
    roses: 'Розы/Эквадор',
    chrysanthemum: 'Хризантемы',
    gvozdika: 'Гвоздика/Китай',
    evkalipt: 'Эвкалипт/Китай',
    giperikum: 'Гиперикум/Китай',
    pistash: 'Писташ',
  },
};
