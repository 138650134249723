<script setup lang="ts">
  const { t } = useI18n();

  const route = useRoute();
  const list = {
    recovery: { title: t('components.auth.header.recovery.title'), description: t('components.auth.header.recovery.description') },
    'recovery-email': { title: t('components.auth.header.recovery-email.title'), description: t('components.auth.header.recovery-email.description') },
    'recovery-verify': { title: t('components.auth.header.recovery-verify.title'), description: t('components.auth.header.recovery-verify.description') },
    'recovery-confirmed': { title: t('components.auth.header.recovery-confirmed.title'), description: t('components.auth.header.recovery-confirmed.description') },
    'recovery-expired': { title: t('components.auth.header.recovery-expired.title'), description: t('components.auth.header.recovery-expired.description') },
    signin: { title: t('components.auth.header.signin.title'), description: t('components.auth.header.signin.descriptionPhone') },
    // signin: { title: t('components.auth.header.signin.title'), description: t('components.auth.header.signin.description') },
    signup: { title: t('components.auth.header.signup.title'), description: t('components.auth.header.signup.description') },
    'signup-email': { title: t('components.auth.header.signup-email.title'), description: t('components.auth.header.signup-email.description') },
    'signup-verify': { title: t('components.auth.header.signup-verify.title'), description: t('components.auth.header.signup-verify.description') },
    'signup-expired': { title: t('components.auth.header.signup-expired.title'), description: t('components.auth.header.signup-expired.description') },
  };

  const currentRoute = computed(() => {
    const name = route.name.split('___')[0] as keyof typeof list
    return list[name]
  });
</script>

<template>
  <header class="text-center">
    <h1>{{ currentRoute?.title }}</h1>
    <h2>{{ currentRoute?.description }}</h2>
  </header>
</template>

<style scoped lang="scss">
header {
  h2 {
    @apply text-gray-2 text-base;
  }
}
</style>
