export enum Page {
  SIGNIN = 'signin',
  SIGNUP = 'signup',
  SIGNUP_EMAIL = 'signup-email',
  SIGNUP_VERIFY = 'signup-verify',
  SIGNUP_EXPIRED = 'signup-expired',
  RECOVERY = 'recovery',
  RECOVERY_EMAIL = 'recovery-email',
  RECOVERY_VERIFY = 'recovery-verify',
  RECOVERY_EXPIRED = 'recovery-expired',
  RECOVERY_CONFIRMED = 'recovery-confirmed',
  TRANSACTION = 'transaction',
  TRANSACTION_REPLENISHMENT = 'transaction-replenishment',
  TRANSACTION_TRANSFER = 'transaction-transfer',
  TRANSACTION_PAYMENTS = 'transaction-payments',
  PROFILE = 'profile',
  PROFILE_VERIFICATION = 'profile-verification',
  OPERATIONS_HISTORY = 'operations-history',
  ORDER = 'order',
  ORDER_MYSELF = 'order-myself',
  CATALOG = 'catalog',
  ASSORTMENT = 'assortment',
  CART = 'cart',
  CONFIRM = 'confirm',
  HISTORY = 'history',
  HISTORY_ID = 'history_id',

  OPERATION = 'operation',

  OPERATION_REPLENISHMENT = 'operation-replenishment',
  OPERATION_PAYMENTS = 'operation-payments',

  OPERATION_ACCEPTANCE = 'operation-acceptance',
  OPERATION_WRITTENOFF = 'operation-writeoff',

  TEST = 'test',
  SETTINGS = 'settings',
  
}
