import AxiosWrapper from '@/utils/axios/wrapper';
import { getApiUrl } from '../url';
import { responseError } from '@/utils/axios/interceptors';

import { CommonQuery, CreateTransactionDto, Transaction } from '@/interfaces';
 
class ApiTransaction extends AxiosWrapper {
  constructor() {
    super(
      {
        config: {
          baseURL: getApiUrl('/transaction'),
          withCredentials: true,
        },
      },
      {
        response: {
          error: [responseError],
        },
      },
    );
  }

  getList(params: CommonQuery) {
    return this.get<Transaction[]>('/list', { params });
  }

  create(data: CreateTransactionDto) {
    return this.post<Transaction>('', data);
  }

  getPaymentUrl(data: string[]) {
    return this.post<{url: string}>('/card/purchase', data);
  }

  getPaymentBalance(data: string[]) {
    return this.post<any>('/balance/purchase', data);
  }

}

export const apiTransaction = new ApiTransaction();
