import { User } from '../user';
import { Account } from '../account';
import type { Timestamp } from '@/interfaces/common';

export interface Transaction extends Timestamp {
  number: number;
  amount: number;
  method: TransactionMethod;
  status: TransactionStatus;
  meta: Record<string, string>;
  responsible: User;
  file: string;
  comment: string;
  commentFile: string;
  account: Account;
  creator: User;
}

export enum TransactionMethod {
  QRCODE = 'QRCODE',
  CRYPTO = 'CRYPTO',
  CASH = 'CASH',
  MANAGER = 'MANAGER',
  CARD = 'CARD',
  BALANCE = 'BALANCE',
}

export enum TransactionMethodName {
  QRCODE = 'QR-код Tinkoff',
  CRYPTO = 'Криптовалюта',
  CASH = 'Оплата наличными',
  MANAGER = 'Внесено менеджером',
  CARD = 'Оплата картой',
  BALANCE = 'Оплата с баланса',
}

export const TransactionMethodList = [
  { _id: TransactionMethod.CARD, name: TransactionMethodName.CARD },
  { _id: TransactionMethod.QRCODE, name: TransactionMethodName.QRCODE },
];

export enum TransactionStatus {
  IN_PROCESSED = 'IN_PROCESSED',
  REJECTED = 'REJECTED',
  CONFIRMED = 'CONFIRMED',
}

export enum PurchaseStatus {
  CREATED = 'Принят',
  CONFIRMED = 'Подтвержден',
  DELIVERY = 'Передан в доставку',
  DELIVERING = 'Доставляется',
  COMPLETED = 'Выполнен',
  REJECTED = 'Отменен',
}

export enum TransactionStatusName {
  IN_PROCESSED = 'В работе',
  REJECTED = 'Отменено',
  CONFIRMED = 'Пополнено',
}

export const TransactionStatusList = [
  { _id: TransactionStatus.IN_PROCESSED, name: TransactionStatusName.IN_PROCESSED },
  { _id: TransactionStatus.REJECTED, name: TransactionStatusName.REJECTED },
  { _id: TransactionStatus.CONFIRMED, name: TransactionStatusName.CONFIRMED },
];

export const PurchaseStatusList = [
  { _id: 'Новый', name: 'Новый' },
  { _id: 'Подтвежден', name: 'Подтвержден' },
  { _id: 'Собран', name: 'Собран' },
  { _id: 'Отгружен', name: 'Отгружен' },
  { _id: 'Доставлен', name: 'Доставлен' },
  { _id: 'Возврат', name: 'Возврат' },
  { _id: 'Отменен', name: 'Отменен' },
  { _id: 'Частично отгружен', name: 'Частично отгружен' },
];

// DTO

export interface CreateTransactionDto {
  user: string;
  method: TransactionMethod;
  meta?: string;
  account: string;
}
