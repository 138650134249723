<script setup lang="ts">
</script>

<template>
  <section class="catalog">
    <section class="catalog-container flex-col">
      <slot></slot>
    </section>
  </section>
</template>

<style lang="scss" scoped>
.catalog {
  @apply padding-top-for-fixed-header;

  &-container {
    @apply px-4 md:px-[5%] py-8 gap-8;
  }
}
</style>
