<script setup lang="ts">
import { apiAuth } from '@/services';
const notification = useNotificationStore();

const { signinPhoneStructure, signinCodeStructure } = useStructureAuth()
const structurePhone = reactive(signinPhoneStructure)
const structureCode = reactive(signinCodeStructure)

const submitPhone = async () => {
  structurePhone.data.password = 'none'
  const sendCode = await apiAuth.signinSendCode(structurePhone.data)
  notification.create({
    type: sendCode.status && sendCode.data ? 'info' : 'error',
    message: sendCode.message,
  });
}

const submitCode = async () => {
  structureCode.data.email = structurePhone.data.email
  structureCode.data.password = `${structureCode.data.password}`
  const signinPhone = await apiAuth.signinPhone(structureCode.data)
  notification.create({
    type: signinPhone.status && signinPhone.data ? 'success' : 'error',
    message: signinPhone.message,
  });
  if (signinPhone.status && signinPhone.data) {
    location.reload()
  }
}

const { t } = useI18n()

useHead({
  title: 'Signin',
  meta: [{ name: 'description', content: 'Signin page' }],
})

definePageMeta({
  layout: 'auth',
  middleware: 'unauth',
})
</script>

<template>
  <section class="signin">
    <main class="signin-container">
      <common-structure :module="structurePhone" @submit="submitPhone"></common-structure>
    </main>
  </section>
  <section class="signin">
    <main class="signin-container">
      <common-structure :module="structureCode" @submit="submitCode"></common-structure>
    </main>
  </section>
</template>

<style scoped lang="scss">
.signin {
  @apply w-full;
}
</style>
