export default {
  useHead: {
    placeAnOrder: 'Placing an order',
  },
  placeAnOrder: 'Placing an order',
  checkTransactionDetails: 'Check transaction details',
  orderList: 'Order list:',
  delivery: 'Delivery',
  pc: 'pc',
  receiptInformation: 'Receipt information:',
  pickup: 'Pickup:',
  address: 'Ramenskoye, st. Mikhalevich 151B (for pickup call +7 (499) 288-74-78)',
  totalPayable: 'Total to be paid:',
  back: 'Back',
  order: 'Order',
  orderIsProcessed: 'Order is processed',
  successfulOperation: 'Successful operation.',
  paymentCompleted: 'The payment for the order has been completed.',
  toCatalog: 'To catalog',
  historyOfOrders: 'History of orders',
  payOrder: 'Pay Order',
  successOrderIsProcessed: 'The order has been successfully completed!',
  payOrderCard: 'Card',
  payOrderCash: 'Cash',
  payOrderBalance: 'Balance',
  selectPaymentMethod: 'Select payment method',

  paidOrderCard: 'Pay by card',
  paidOrderCash: 'Pay in cash',
  paidOrderBalance: 'Pay from balance',

  deliveryMethod: 'Delivery  method',
 
}
