export default {
  useHead: {
    title: 'Корзина',
  },
  cart: 'Корзина',
  error: 'Произошла ошибка, товары в корзине могли измениться',
  today: 'Сегодня',
  nothingHere: 'Здесь пока ничего нет',
  cartIsEmpty: 'Корзина пуста',
  clickToReplenish: 'Нажмите на кнопку “В корзину” на карточке цветка в Каталоге, чтобы пополнить Корзину.',
  toCatalog: 'В каталог',
  height: 'Рост:',
  cm: 'см',
  price: 'Цена:',
  flower: '/цветок',
  forAll: 'за все',
  flowersInStock: 'цветков в наличии',
  total: 'Итого:',
  inStock: 'В наличии:',
  forRegistration: 'К оформлению',
  delivery: 'Доставка:',
  comment: 'Комментарий:',
  'delivery-cost-info': 'При стоимости заказа от 10 000 и выше, доставка бесплатна.',
}
