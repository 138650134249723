<script setup lang="ts">
import type { ButtonProps } from '@/interfaces'

const props = defineProps<ButtonProps>()

const nuxtLinkComp = resolveComponent('NuxtLink')

const component = computed(() => {
  return props.to ? nuxtLinkComp : 'button'
})
</script>

<template>
  <component :is="component" class="btn flex flex-ic" :disabled="disabled || false" :class="classMods"
    :type="type || 'button'" :to="to">
    <slot v-if="icon"></slot>
    <span>{{ text }}</span>
  </component>
</template>

<style scoped lang="scss">
.btn {
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @apply w-full relative gap-2 py-2 px-4 font-medium rounded-md transition-all duration-300;

  &-black {
    @apply bg-black text-white;
  }

  &-accent {
    @apply bg-accent text-white;
  }

  &-fit {
    @apply w-fit;
  }

  &-border {
    @apply border-accent text-accent;
  }

  &-border-black {
    @apply border-black text-black;
  }

  &-center {
    @apply justify-center;
  }

  &-small {
    @apply px-3 text-sm;
  }

  &-disabled {
    @apply opacity-50;
  }

  &.greenGradient {
    background-color: rgba(25, 177, 59, 0.15);
  }

  &.accentGradient {
    background-color: rgba(199, 59, 46, 0.15);
  }
}
</style>
