<script setup lang="ts">
import { RecipientCreateType, StructureModule } from '@/interfaces';
const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const emit = defineEmits<{
  (e: 'submit'): void;
  (e: 'updatedStructure'): void;
}>();

const { module } = defineProps<{
  module: StructureModule;
  className?: string;
}>();

const data = reactive({
  ripButton: false as boolean,
});

const ripB = async () => {
  if (module.inputs[0].type === 'phone') {
    data.ripButton = true
    await wait(30000)
    data.ripButton = false
  }
}

const dropHandler = (id: string) => {
  module.inputs.filter((input) => input.id !== id).forEach((input) => (input.drop_show = false));
};

const dropManipulation = (id: string) => {
  const data = module.data;
  const inputs = module.inputs;

  if (module.id?.includes('recipient')) {
    if (id === 'type') {
      const isNewType = data.type === RecipientCreateType.NEW;

      const plantation = inputs.find((i) => i.id === 'plantation');
      const plantationName = inputs.find((i) => i.id === 'plantationName');
      if (!plantation || !plantationName) return;

      plantation.required = plantation.show = !isNewType;
      plantationName.required = plantationName.show = isNewType;
    }

    if (id === 'country') {
      const isUSA = data.country === 'US';

      const swift = inputs.find((i) => i.id === 'swift');
      const routingNumber = inputs.find((i) => i.id === 'routingNumber');
      if (!swift || !routingNumber) return;

      swift.required = swift.show = !isUSA;
      routingNumber.required = routingNumber.show = isUSA;
    }
  }

  if (module.id?.includes('plantation')) {
    const c = module.id === 'plantation-create' ? 'plantationCountry' : 'country';
    if (id === c) {
      const state = inputs.find((i) => i.id === 'state');
      if (!state) return;

      state.show = state.required = data[id] === 'US';
    }
  }
};

const updateStructure = (id?: string) => {
  emit('updatedStructure');
  if (id) dataManipulation(id);
};

const toggleSwitcher = (id: string) => { };

const dataManipulation = (id: string) => {
  const data = module.data;

  if (module.id === 'plantation-create' && id === 'name') {
    const disabled = !!data._id;
    const inputs = module.inputs.filter((i) =>
      ['city', 'address', 'zip', 'plantationCountry'].includes(i.id),
    );
    for (const input of inputs) input.disabled = disabled;
  }
};
</script>

<template>
  <section class="structure flex-col">
    <div class="structure-grid" :class="module.classList">
      <common-structure-input v-for="input of module.inputs" :key="input.id" :data="module.data" :input="input"
        :class-name="className" @drop-handler="dropHandler" @drop-manipulation="dropManipulation"
        @update-structure="updateStructure" @toggle-switcher="toggleSwitcher"></common-structure-input>
    </div>
    <div v-if="module.inputs[0].type === 'phone'" class="terms-text mt-4 text-sm">
      Отправляя смс, вы принимаете
      <a href="/privacy-policy" target="_blank" class="text-blue-500 underline">политику конфиденциальности</a>
      и ознакомились с
      <a href="/offer" target="_blank" class="text-blue-500 underline">офертой</a>.
    </div>
    <div v-if="module.submit && !data.ripButton" class="structure-submit">
      <common-button v-bind="module.submit" @click="emit('submit'), ripB()">test</common-button>
    </div>
  </section>
</template>

<style scoped lang="scss">
.structure {
  @apply gap-8;

  &-grid {
    grid-template-columns: repeat(12, 1fr);
    @apply grid gap-4;
  }
}

.terms-text {
  text-align: center;
}
</style>
